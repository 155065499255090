var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.contractor)?_c('div',{staticClass:"container-fluid bg-gray-lighter"},[_c('div',{staticClass:"md:w-1/2 pt-8 pb-64 px-4 md:px-12"},[_c('breadcrumbs',{staticClass:"mt-2 mb-1",attrs:{"path":[
        {
          name: _vm.$t('add-contractor-user-view.contractors'),
          url: { name: 'Admins' },
        },
        {
          name: _vm.contractor.name,
          url: {
            name: 'ContractorDetails',
            params: { contractorId: _vm.contractor.id },
          },
        },
        { name: _vm.$t('add-contractor-user-view.add-user') } ]}}),_c('contractor-user-form',{staticClass:"mb-64",attrs:{"id":"contractorUserForm","clear":_vm.submissionEndSuccessfully},on:{"on-submit":_vm.handleSubmission}})],1),_c('footer-menu',[_c('div',{staticClass:"flex justify-center xs:justify-between flex-wrap"},[_c('div',{staticClass:"flex"},[_c('btn',{staticClass:"md:w-48 mr-3",attrs:{"is-loading":_vm.redirectAfterSubmission && _vm.submissionInProgress,"type":"submit","form":"contractorUserForm"},on:{"click":function($event){_vm.redirectAfterSubmission = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")]),_c('btn',{staticClass:"w-48 md:w-auto md:mx-3",attrs:{"is-loading":!_vm.redirectAfterSubmission && _vm.submissionInProgress,"type":"submit","form":"contractorUserForm","theme":"secondary","compact":_vm.mobileView},on:{"click":function($event){_vm.redirectAfterSubmission = false}}},[_vm._v(" "+_vm._s(_vm.$t('add-contractor-user-view.save-and-add-another'))+" ")])],1),_c('btn',{staticClass:"mt-2 xs:mt-0",attrs:{"theme":"none"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }