<template>
  <div v-if="contractor" class="container-fluid bg-gray-lighter">
    <div class="md:w-1/2 pt-8 pb-64 px-4 md:px-12">
      <breadcrumbs
        :path="[
          {
            name: $t('add-contractor-user-view.contractors'),
            url: { name: 'Admins' },
          },
          {
            name: contractor.name,
            url: {
              name: 'ContractorDetails',
              params: { contractorId: contractor.id },
            },
          },
          { name: $t('add-contractor-user-view.add-user') },
        ]"
        class="mt-2 mb-1"
      ></breadcrumbs>
      <contractor-user-form
        id="contractorUserForm"
        class="mb-64"
        :clear="submissionEndSuccessfully"
        @on-submit="handleSubmission"
      />
    </div>
    <footer-menu>
      <div class="flex justify-center xs:justify-between flex-wrap">
        <div class="flex">
          <btn
            :is-loading="redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="contractorUserForm"
            class="md:w-48 mr-3"
            @click="redirectAfterSubmission = true"
          >
            {{ $t('common.save') }}
          </btn>
          <btn
            :is-loading="!redirectAfterSubmission && submissionInProgress"
            type="submit"
            form="contractorUserForm"
            class="w-48 md:w-auto md:mx-3"
            theme="secondary"
            :compact="mobileView"
            @click="redirectAfterSubmission = false"
          >
            {{ $t('add-contractor-user-view.save-and-add-another') }}
          </btn>
        </div>
        <btn theme="none" class="mt-2 xs:mt-0" @click="$router.go(-1)">
          {{ $t('common.cancel') }}
        </btn>
      </div>
    </footer-menu>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue';
import Btn from '@/components/Button/Button.vue';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import ContractorUserForm from '@/views/Dashboard/Contractors/Forms/ContractorUserForm';
import { mapActions, mapState } from 'vuex';
import VueStore from '@/store';

export default {
  name: 'AddContractorUser',
  components: {
    Breadcrumbs,
    Btn,
    FooterMenu,
    ContractorUserForm,
  },
  async beforeRouteEnter(to, from, next) {
    await VueStore.dispatch(
      'contractor/fetchContractorDetails',
      to.params.contractorId,
    );
    next();
  },
  data() {
    return {
      redirectAfterSubmission: false,
      submissionInProgress: false,
      submissionEndSuccessfully: false,
    };
  },
  computed: {
    ...mapState('contractor', {
      contractor: 'details',
    }),
    ...mapState('ui', {
      mobileView: (state) => state.mobileView.active,
    }),
  },
  methods: {
    ...mapActions('contractor', ['addContractorUser']),
    async handleSubmission(data) {
      this.submissionEndSuccessfully = false;
      this.submissionInProgress = true;
      try {
        await this.addContractorUser(data);

        this.submissionEndSuccessfully = true;

        if (this.redirectAfterSubmission)
          this.$router.push({
            name: 'ContractorDetails',
            params: { contractorId: this.contractor.id },
          });

        this.$toast.success(this.$t('add-contractor-user-view.user-added'));
      } catch (err) {
        this.submissionEndSuccessfully = false;
      }
      this.submissionInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  @apply min-h-screen;
}
</style>
